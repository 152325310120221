import React, { Component } from 'react';
import * as signalR from "@microsoft/signalr";
import './Collapsible.css';

export class ServiceRequests extends Component {
    constructor(props) {
        super(props);

        this.callId = '';
        this.psapId = '';
        this.queueId = '';
        this.latitude = 0.00;
        this.longitude = 0.00;
        this.deviation = 0.00;
        this.singleTextInput = '';
        this.textHistory = '';
        this.textHistoryJson = '';
        this.textHistoryTextFormat = '';
        this.pushCallState = 'offered';
        this.psapIdIsSet = true;
        this.queueIdIsSet = true;
        this.state = {
            dates: [],
            options: [],
            apiCallResponse: [],
            apiCallResponsePoint: [],
            apiCallResponseCircle: [],
            apiLocationUpdatedResponsePoint: [],
            apiLocationUpdatedResponseCircle: [],
            apiTextResponse: [],
            apiHtmlResponse: [],
            apiConnectedResponse: [],
            apiDisconnectedResponse: [],
            apiCancelledResponse: [],
            apiAbandonedResponse: [],
            apiStatuscheckResponse: [],
            apiQueueStateResponse: [],
            apiElementStateResponse: [],
            apiSecurityPostureResponse: [],
            apiServiceStateResponse: [],
            apiTextHistoryResponse: [],
            apiTextJsonResponse: [],
            apiTextFormatResponse: [],
            apiLocationUpdatedResponse: [],
            apiReRegisterResponse: [],
            apiTransferredResponse: [],
            apiTransferFailedResponse: [],
            apiHandoverPushResponse: [],
            apiHandoverPulledResponse: [],
            buttonLoading: false,
            messages: [],
            hubConnection: null
        };

        let generatedCallId = this.generateCallId();
        let option = <option value={generatedCallId}> {generatedCallId} </option>;
        this.state.options.push(option);
        this.setState({ options: this.state.options });
        this.collapseExpand = (id) => {
            var button = document.getElementById(id);
            button.classList.toggle("active");
            var content = button.nextElementSibling;
            if (content.style.display === "block") {
                content.style.display = "none";
            } else {
                content.style.display = "block";
            }
        }
    }

    apiCall = async (apiRoute, stateField) => {

        const stateFieldToUpdate = stateField;
        this.setState({ buttonLoading: true });
        let baseUrl = window.location.origin.toString();
        this.setState({ [stateFieldToUpdate]: ' ' });
        const result = await fetch(baseUrl + apiRoute);
        const resultJson = await result.json();
        this.setState({ [stateFieldToUpdate]: resultJson, buttonLoading: false });
    };

    setAtosError404 = async () => {
        var isErrorState = document.getElementById('atoserror404').value;
        let baseUrl = window.location.origin.toString();
        console.log(JSON.stringify(isErrorState));
        const result = await fetch(baseUrl + `/api/v1/psap/${this.getPsapId.call()}/errorstate404`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(isErrorState)
        });
    };

    componentDidMount = () => {

        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl("/servicerequests")
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        this.setState({ hubConnection }, () => {
            this.state.hubConnection
                .start()
                .then(() => console.log('Connection started!'))
                .catch(err => console.log('Error while establishing connection :('));

            this.state.hubConnection.on('sendToAll', (receivedMessage) => {
                const text = `${receivedMessage}`;
                const messages = this.state.messages.concat([text]);
                const dates = this.state.dates.concat([new Date().toLocaleDateString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })]);

                messages.unshift(messages.pop());
                dates.unshift(dates.pop());

                this.setState({ messages: messages, dates: dates });
            });

            document.getElementById('setPsapIdButton').disabled = true;
            document.getElementById('setQueueIdButton').disabled = true;
            document.getElementById('latitudeInput').value = "0.000000";
            document.getElementById('longitudeInput').value = "0.000000";
            document.getElementById('radiusInput').value = "0.000000";
        });
    };

    static renderRequestsTable(requestData) {
        return (
            <table className='table table-striped' aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Request</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        requestData.messages.map((message, index) => (
                            <tr key={index}>
                                <td>{requestData.dates[index]}</td>
                                <td><pre>{message}</pre></td>
                            </tr>
                        ))

                    }
                </tbody>
            </table >
        );
    }

    storeCallId = () => {

        if (!this.callIdExists.call()) {

            ServiceRequests.callId = document.getElementById('callIdInput').value;
            document.getElementById('callIdInput').value = "";
            let option = <option value={ServiceRequests.callId} >{ServiceRequests.callId}</option>;
            this.state.options.push(option);
            this.state.options.unshift(this.state.options.pop());
            this.setState({ options: this.state.options });
        }
    }

    storeCoordinates = () => {

        this.latitude = document.getElementById('latitudeInput').value;
        this.longitude = document.getElementById('longitudeInput').value;
        this.deviation = document.getElementById('radiusInput').value;
        return true;
    }

    storeSingleTextInput = () => {
        this.singleTextInput = document.getElementById('singleTextInput').value;
        return true;
    }

    storeTextHistory= () => {
        this.textHistory = encodeURIComponent(document.getElementById('textHistory').value);
        return true;
    }

    storeTextHistoryJson = () => {
        this.textHistoryJson = encodeURIComponent(document.getElementById('textHistoryJson').value);
        return true;
    }

    storeTextHistoryTextFormat = () => {
        this.textHistoryTextFormat = encodeURIComponent(document.getElementById('textHistoryTextFormat').value);
        return true;
    }

    storePushCallState = () => {
        this.pushCallState = document.getElementById('pushCallState').value;
        return true;
    }

    storeGeneratedCallId = () => {

        ServiceRequests.callId = this.generateCallId();
        let option = <option value={ServiceRequests.callId} >{ServiceRequests.callId}</option>;
        this.state.options.push(option);
        this.state.options.unshift(this.state.options.pop());
        this.setState({ options: this.state.options });
    }

    getCallId = () => {
        return ServiceRequests.callId = document.getElementById('callIdList').value;
    }

    generateCallId = () => {
        let callId = this.generateRandomString(1, "123456789");
        callId += this.generateRandomString(9, "0123456789");
        callId += this.generateRandomString(2, "abcdefghijklmnopqrstuvwxyz");
        callId += this.generateRandomString(12, "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ")

        return callId;
    }

    generateRandomString = (length, chars) => {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];

        return result;
    }

    callIdExists = () => {

        let inputCallId = document.getElementById('callIdInput').value.toLowerCase();

        if (inputCallId == null || inputCallId.trim() === '') {

            document.getElementById("emptyValueText").hidden = false;
            document.getElementById("existText").hidden = true;
            return true;
        }
        else if (this.state.options.length > 0 && this.state.options.filter(obj => obj.props.value === inputCallId).length > 0) {

            document.getElementById("existText").hidden = false;
            document.getElementById("emptyValueText").hidden = true;
            return true;
        }
        else {
            document.getElementById("existText").hidden = true;
            document.getElementById("emptyValueText").hidden = true;
            return false;
        }
    }

    setPsapId = () => {
        let psapIdInput = document.getElementById('psapIdInput');
        let psapIdValidationMessage = document.getElementById('psapIdValidationMessage');
        let setButton = document.getElementById('setPsapIdButton');
        let editButton = document.getElementById('editPsapIdButton');

        if (psapIdInput.value === null || psapIdInput.value === '') {
            psapIdValidationMessage.hidden = false;
            this.psapIdIsSet = false;
        }
        else {
            if (!psapIdValidationMessage.hidden) {
                psapIdValidationMessage.hidden = true;
            }
            if (!setButton.disabled) {
                setButton.disabled = true;
            }
            if (editButton.disabled) {
                editButton.disabled = false;
            }

            ServiceRequests.psapId = psapIdInput.value;
            document.getElementById("psapIdInput").readOnly = true;
            this.psapIdIsSet = true;
        }
    }

    getPsapId = () => {
        return ServiceRequests.psapId = document.getElementById('psapIdInput').value;
    }

    psapIdEditClicked = () => {
        let psapIdInput = document.getElementById('psapIdInput');
        let setButton = document.getElementById('setPsapIdButton');
        let editButton = document.getElementById('editPsapIdButton');

        if (psapIdInput.readOnly) {
            psapIdInput.readOnly = false;
        }
        if (setButton.disabled) {
            setButton.disabled = false;
        }
        if (!editButton.disabled) {
            editButton.disabled = true;
        }
        this.psapIdIsSet = false;
    }

    psapIsSet = () => {
        if (!this.psapIdIsSet) {
            alert('The psapId must be set before continuing');
            return false;
        }
        return true;
    }

    setQueueId = () => {
        let queueIdInput = document.getElementById('queueIdInput');
        let queueIdValidationMessage = document.getElementById('queueIdValidationMessage');
        let setButton = document.getElementById('setQueueIdButton');
        let editButton = document.getElementById('editQueueIdButton');

        if (queueIdInput.value === null || queueIdInput.value === '') {
            queueIdValidationMessage.hidden = false;
            this.queueIdIsSet = false;
        }
        else {
            if (!queueIdValidationMessage.hidden) {
                queueIdValidationMessage.hidden = true;
            }
            if (!setButton.disabled) {
                setButton.disabled = true;
            }
            if (editButton.disabled) {
                editButton.disabled = false;
            }

            ServiceRequests.queueId = queueIdInput.value;
            document.getElementById("queueIdInput").readOnly = true;
            this.queueIdIsSet = true;
        }
    }

    getQueueId = () => {
        return ServiceRequests.queueId = document.getElementById('queueIdInput').value;
    }

    queueIdEditClicked = () => {
        let queueIdInput = document.getElementById('queueIdInput');
        let setButton = document.getElementById('setQueueIdButton');
        let editButton = document.getElementById('editQueueIdButton');

        if (queueIdInput.readOnly) {
            queueIdInput.readOnly = false;
        }
        if (setButton.disabled) {
            setButton.disabled = false;
        }
        if (!editButton.disabled) {
            editButton.disabled = true;
        }
        this.queueIdIsSet = false;
    }

    queueIsSet = () => {
        if (!this.queueIdIsSet) {
            alert('The queueId must be set before continuing');
            return false;
        }
        return true;
    }

    render() {

        let serviceRequests = ServiceRequests.renderRequestsTable(this.state);
        const messageStyle = { color: 'red' };


        return (
            <div>
                <div>
                    <div style={{ "display": "inline-block", "margin-right": "50px", "margin-top": "2px", "vertical-align": "top" }}>
                        <label for="callIdList"><p><b> Call ID under test</b></p> </label>
                        &nbsp;&nbsp;
                        <select id="callIdList" onChange={() => this.getCallId.call()}>
                            {this.state.options}
                        </select>
                        <br />
                        <input type="button" value="Generate Call ID" onClick={() => this.storeGeneratedCallId.call()} />
                    </div>
                    <div style={{ "display": "inline-block", "margin-top": "2px" }}>
                        <form id="callIdForm">
                            <div>
                                <input id="callIdInput" type="text" name="name" placeholder="enter Call ID" />
                                &nbsp;&nbsp;
                                <input type="button" value="Add" onClick={() => this.storeCallId.call()} />
                                <div><p style={messageStyle} id="existText" hidden> <b>Call ID already exists</b></p></div>
                                <div><p style={messageStyle} id="emptyValueText" hidden> <b>Enter a new Call ID value</b></p></div>
                            </div>
                            <br />
                            <div>
                                <input id="psapIdInput" type="text" name="psapInput" defaultValue="esapptest.bocalab.com" readOnly=
                                    {true} />
                                &nbsp;&nbsp;
                                <input type="button" id="setPsapIdButton" value="Set" onClick={() => this.setPsapId.call()} />
                                &nbsp;&nbsp;
                                <input type="button" id="editPsapIdButton" value="Edit" onClick={() => this.psapIdEditClicked.call()} />
                                <div><p style={messageStyle} id="psapIdValidationMessage" hidden> <b>PsapId cannot be empty</b></p></div>
                            </div>
                            <div>
                                <input id="queueIdInput" type="text" name="queueInput" defaultValue="queue1" readOnly=
                                    {true} />
                                &nbsp;&nbsp;
                                <input type="button" id="setQueueIdButton" value="Set" onClick={() => this.setQueueId.call()} />
                                &nbsp;&nbsp;
                                <input type="button" id="editQueueIdButton" value="Edit" onClick={() => this.queueIdEditClicked.call()} />
                                <div><p style={messageStyle} id="queueIdValidationMessage" hidden> <b>QueueId cannot be empty</b></p></div>
                            </div>
                        </form>
                    </div>
                    <div style={{ "display": "inline-block", "margin-right": "70px", "margin-top": "2px" }}>
                        <div>
                            <input id="latitudeInput" type="text" name="name" placeholder="enter latitude: 0.000000" />
                            &nbsp;&nbsp;
                        <input id="longitudeInput" type="text" name="name" placeholder="enter longitude: 0.000000" />
                            &nbsp;&nbsp;
                        <input id="radiusInput" type="text" name="name" placeholder="enter deviation: 0.000000" />
                            &nbsp;&nbsp;
                        <input type="button" value="Set Coordinates" onClick={() => this.storeCoordinates.call()} />
                        </div>
                    </div>
                    <br />
                    <div style={{ "display": "inline-block", "margin-right": "70px", "margin-top": "2px" }}>
                        <div>
                            <label for="atoserror404"><p>Simulate frontend not registered (404) error from Atos</p> </label>
                            &nbsp;&nbsp;
                            <select id="atoserror404" onChange={() => this.setAtosError404()}>
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>
                    </div>
                </div>
                <br />
                <div class="div1">
                    <table className='table table-borderless'>
                        <thead>
                            <tr>
                                <th>GatewayApi</th> 
                            </tr>
                        </thead>
                        <tbody>
                            <button type="button" class="collapsible" id="offer | location" onClick={() => this.collapseExpand("offer | location")}>Offer&nbsp;|&nbsp;Loc</button>
                            <div class="content" style={{ "border": "1px solid lightgrey" }}>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call() && this.queueIsSet.call() && this.storeCoordinates.call()) this.apiCall(`/api/v1/client/call/${this.getCallId.call()}/offer/point/psap/${this.getPsapId.call()}/queue/${this.getQueueId.call()}?latitude=${this.latitude}&longitude=${this.longitude}&deviation=${this.deviation}`, 'apiCallResponsePoint') }}>call:offer:point</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiCallResponsePoint.gatewayApiCallResultPoint}>{this.state.apiCallResponsePoint.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call() && this.storeCoordinates.call()) this.apiCall(`/api/v1/client/call/${this.getCallId.call()}/updated/point?latitude=${this.latitude}&longitude=${this.longitude}&deviation=${this.deviation}`, 'apiLocationUpdatedResponsePoint') }}>call:updated:point</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiLocationUpdatedResponsePoint.gatewayApiCallResultUpdatePoint}>{this.state.apiLocationUpdatedResponsePoint.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call() && this.queueIsSet.call() && this.storeCoordinates.call()) this.apiCall(`/api/v1/client/call/${this.getCallId.call()}/offer/circle/psap/${this.getPsapId.call()}/queue/${this.getQueueId.call()}?latitude=${this.latitude}&longitude=${this.longitude}&deviation=${this.deviation}`, 'apiCallResponseCircle') }}>call:offer:circle</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiCallResponseCircle.gatewayApiCallResultCircle}>{this.state.apiCallResponseCircle.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call() && this.storeCoordinates.call()) this.apiCall(`/api/v1/client/call/${this.getCallId.call()}/updated/circle?latitude=${this.latitude}&longitude=${this.longitude}&deviation=${this.deviation}`, 'apiLocationUpdatedResponseCircle') }}>call:updated:circle</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiLocationUpdatedResponseCircle.gatewayApiCallResultUpdateCircle}>{this.state.apiLocationUpdatedResponseCircle.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call() && this.queueIsSet.call() && this.storeCoordinates.call()) this.apiCall(`/api/v1/client/call/${this.getCallId.call()}/abandoned/psap/${this.getPsapId.call()}/queue/${this.getQueueId.call()}?latitude=${this.latitude}&longitude=${this.longitude}&deviation=${this.deviation}`, 'apiAbandonedResponse') }}>call:abandoned</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiAbandonedResponse.gatewayApiCallResult}>{this.state.apiAbandonedResponse.message}</a></td>
                                </tr>
                            </div>
                            <button type="button" class="collapsible" id="messaging" onClick={() => this.collapseExpand("messaging")}>Messaging</button>
                            <div class="content" style={{ "border": "1px solid lightgrey" }}>
                                <tr>
                                    <td>
                                        <input id="singleTextInput" type="text" placeholder="Enter single message i.e mssg1" size="26" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call() && this.storeSingleTextInput.call()) this.apiCall(`/api/v1/client/call/${this.getCallId.call()}/text?message=${this.singleTextInput}`, 'apiTextResponse') }}>call:text:plain</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiTextResponse.gatewayApiTextResult}>{this.state.apiTextResponse.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call() && this.storeSingleTextInput.call()) this.apiCall(`/api/v1/client/call/${this.getCallId.call()}/text/html?message=${this.singleTextInput}`, 'apiHtmlResponse') }}>call:text:html</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiHtmlResponse.gatewayApiTextResult}>{this.state.apiHtmlResponse.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        &nbsp;
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <textarea id="textHistory" type="text" rows="3" cols="28" placeholder="May enter single or multiple message(s) separated by semicolon. i.e mssg1 Or mssg1;mssg2"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call() && this.storeTextHistory.call()) this.apiCall(`/api/v1/client/call/${this.getCallId.call()}/texthistory?message=${this.textHistory}`, 'apiTextHistoryResponse') }}>call:textHistory</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiTextHistoryResponse.gatewayApiTextResult}>{this.state.apiTextHistoryResponse.message}</a></td>
                                </tr>
                            </div>
                            <button type="button" class="collapsible" id="session" onClick={() => this.collapseExpand("session")}>Session</button>
                            <div class="content" style={{ "border": "1px solid lightgrey" }}>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call()) this.apiCall(`/api/v1/client/call/${this.getCallId.call()}/connected`, 'apiConnectedResponse') }}>call:connected</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiConnectedResponse.gatewayApiCallResult}>{this.state.apiConnectedResponse.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call()) this.apiCall(`/api/v1/client/call/${this.getCallId.call()}/disconnected`, 'apiDisconnectedResponse') }}>call:disconnected</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiDisconnectedResponse.gatewayApiCallResult}>{this.state.apiDisconnectedResponse.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call()) this.apiCall(`/api/v1/client/call/${this.getCallId.call()}/cancelled`, 'apiCancelledResponse') }}>call:cancelled</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiCancelledResponse.gatewayApiCallResult}>{this.state.apiCancelledResponse.message}</a></td>
                                </tr>
                            </div>
                            <button type="button" class="collapsible" id="transfer" onClick={() => this.collapseExpand("transfer")}>Transfer</button>
                            <div class="content" style={{ "border": "1px solid lightgrey" }}>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => this.apiCall(`/api/v1/client/call/${this.getCallId.call()}/transferred`, 'apiTransferredResponse')}>call:transferpsap</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiTransferredResponse.gatewayApiCallResult}>{this.state.apiTransferredResponse.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => this.apiCall(`/api/v1/client/call/${this.getCallId.call()}/transferfailed`, 'apiTransferFailedResponse')}>call:transferfailedpsap</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiTransferFailedResponse.gatewayApiCallResult}>{this.state.apiTransferFailedResponse.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        &nbsp;
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <textarea id="textHistoryJson" type="text" rows="3" cols="28" placeholder="Must enter 2 messages separated by a semicolon i.e. mssg1;mssg2" />
                                    </td>
                                </tr> 
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call() && this.storeTextHistoryJson.call()) this.apiCall(`/api/v1/client/call/${this.getCallId.call()}/text/json?message=${this.textHistoryJson}`, 'apiTextJsonResponse') }}>call:textHistory:json</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiTextJsonResponse.gatewayApiTextResult}>{this.state.apiTextJsonResponse.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        &nbsp;
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <textarea id="textHistoryTextFormat" type="text" rows="3" cols="28" placeholder="Must enter 3 messages separated by a semicolon i.e. mssg1;mssg2;mssg3" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call() && this.storeTextHistoryTextFormat.call()) this.apiCall(`/api/v1/client/call/${this.getCallId.call()}/text/textformat?message=${this.textHistoryTextFormat}`, 'apiTextFormatResponse') }}>call:textHistory:textFrmt</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiTextFormatResponse.gatewayApiTextResult}>{this.state.apiTextFormatResponse.message}</a></td>
                                </tr>
                            </div>
                            <button type="button" class="collapsible" id="state" onClick={() => this.collapseExpand("state")}>State</button>
                            <div class="content" style={{ "border": "1px solid lightgrey" }}>   
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call() && this.queueIsSet.call()) this.apiCall(`/api/v1/client/psap/${this.getPsapId.call()}/queuestate/queue/${this.getQueueId.call()}`, 'apiQueueStateResponse') }}>queueState</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiQueueStateResponse.gatewayApiStateResult}>{this.state.apiQueueStateResponse.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call()) this.apiCall(`/api/v1/client/psap/${this.getPsapId.call()}/elementstate`, 'apiElementStateResponse') }}>elementState</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiElementStateResponse.gatewayApiStateResult}>{this.state.apiElementStateResponse.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call()) this.apiCall(`/api/v1/client/psap/${this.getPsapId.call()}/securityposture`, 'apiSecurityPostureResponse') }}>securityPosture</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiSecurityPostureResponse.gatewayApiStateResult}>{this.state.apiSecurityPostureResponse.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call()) this.apiCall(`/api/v1/client/psap/${this.getPsapId.call()}/servicestate`, 'apiServiceStateResponse') }}>serviceState</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiServiceStateResponse.gatewayApiStateResult}>{this.state.apiServiceStateResponse.message}</a></td>
                                </tr>
                            </div> 
                            <button type="button" class="collapsible" id="misc" onClick={() => this.collapseExpand("misc")}>Misc</button>
                            <div class="content" style={{ "border": "1px solid lightgrey" }}>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call()) this.apiCall(`/api/v1/client/frontend/reregister`, 'apiReRegisterResponse') }}>reregister</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiReRegisterResponse.gatewayApiRegisterResult}>{this.state.apiReRegisterResponse.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call()) this.apiCall('/api/v1/client/frontend/statuscheck', 'apiStatuscheckResponse') }}>status:check</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiStatuscheckResponse.gatewayApiStatusResult}>{this.state.apiStatuscheckResponse.message}</a></td>
                                </tr>
                            </div>
                            <button type="button" class="collapsible" id="handover" onClick={() => this.collapseExpand("handover")}>Handover</button>
                            <div class="content" style={{ "border": "1px solid lightgrey" }}>
                                <tr>
                                    <td>
                                        <select id="pushCallState" onChange={() => this.storePushCallState.call()}>
                                            <option value="offered" selected>offered</option>
                                            <option value="accepted">accepted</option>
                                            <option value="answered">answered</option>
                                        </select>
                                    </td>
                                </tr> 
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call()) this.apiCall(`/api/v1/client/call/push?callId=${this.getCallId.call()}&psapId=${this.getPsapId.call()}&callState=${this.pushCallState}`, 'apiHandoverPushResponse') }}>call:push</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiHandoverPushResponse.gatewayApiHandoverResult}>{this.state.apiHandoverPushResponse.message}</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        &nbsp;
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {<button className="btn btn-primary" disabled={this.state.buttonLoading} onClick={() => { if (this.psapIsSet.call()) this.apiCall(`/api/v1/client/call/pulled`, 'apiHandoverPulledResponse') }}>call:pulled</button>}
                                    </td>
                                    <td><a href="#" title={this.state.apiHandoverPulledResponse.gatewayApiHandoverResult}>{this.state.apiHandoverPulledResponse.message}</a></td>
                                </tr>
                            </div>
                        </tbody>
                    </table>
                </div>
                <div class="div2">
                    <h1>Service Requests</h1>
                    <p>Requests Received by Atos Service mock implementation.</p>
                    {serviceRequests}
                </div>
            </div >
        );
    }
}

export default ServiceRequests;
